import _get from 'lodash/get';
import PropTypes from 'prop-types';

// helpers
import { credibilityQuoteWithImageAdapter } from 'src/components/Credibility';
import { mvpSocialProofAdapter } from 'src/components/Layout';
import { formCtaAdapter } from 'src/components/FormCta';
import {
  customerStoryTileAdapter,
  featuredStoryAdapter,
} from 'src/components/CustomerStories';

// constants
import { Contentful, CustomerStoriesComponentTypes } from 'src/lib/constants';

// types
import { ImageShape, CredibilityQuoteTypes } from 'src/lib/prop-types.js';

// TODO: replace prop types with typescript
export const CustomerStoriesOverviewPropTypes = {
  metaData: PropTypes.shape({
    metaDescription: PropTypes.string,
    metaTitle: PropTypes.string,
  }).isRequired,
  pageHeaderData: PropTypes.shape({
    primaryHeading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
  }).isRequired,
  featuredStoryData: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    subhead: PropTypes.string,
    image: ImageShape,
    url: PropTypes.string,
    'bg-classes': PropTypes.string,
  }).isRequired,
  storiesData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      goals: PropTypes.arrayOf(PropTypes.string),
      logo: PropTypes.string,
      excerpt: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
  credibilityData: PropTypes.shape({
    image: ImageShape,
    ...CredibilityQuoteTypes,
  }).isRequired,
  socialproofData: PropTypes.shape({
    header: PropTypes.string,
    alignment: PropTypes.string,
    'column-count': PropTypes.number,
    fullWidth: PropTypes.bool,
    logos: PropTypes.arrayOf(ImageShape),
  }).isRequired,
  // TODO: fix this? ctaData: PropTypes.shape(ctaPropTypes),
  // TODO: add locale
};

// TODO: test this
export const metaDataAdapter = (cmsData, staticData) => {
  return {
    metaTitle: cmsData?.fields?.metaTitle || staticData['meta-title'],
    metaDescription:
      cmsData?.fields?.metaDescription || staticData['meta-description'],
  };
};

/**
 * @param {Object} cmsData
 * @param {Object} cmsData.fields - CMS entry's fields, including array of components
 * @returns {Object} key:value mapping of components
 */
export const makeComponentMap = ({ fields: { components = [] } = {} } = {}) => {
  const reducer = (acc, current) => {
    acc[_get(current, Contentful.CONTENT_TYPE_ID)] = current;
    return acc;
  };

  return components.reduce(reducer, {});
};

/**
 * @param {Object} cmsComponentData - CMS entry's component
 * @param {Object} staticData - data/props from static source
 * @returns {Object} expected shape of props for view, using CMS data if provided otherwise using staticData as fallback
 */
export const getPropsFromData = (cmsComponentData, staticData) => {
  switch (_get(cmsComponentData, Contentful.CONTENT_TYPE_ID)) {
    case CustomerStoriesComponentTypes.PAGE_HEADER: {
      return {
        primaryHeading: cmsComponentData?.fields?.primaryHeading,
        subheading: cmsComponentData?.fields?.subheading,
      };
    }

    case CustomerStoriesComponentTypes.SOCIAL_PROOF: {
      return mvpSocialProofAdapter(cmsComponentData?.fields);
    }

    case CustomerStoriesComponentTypes.QUOTE_WITH_IMAGE: {
      return credibilityQuoteWithImageAdapter(cmsComponentData?.fields);
    }

    case CustomerStoriesComponentTypes.FORM_CTA: {
      return formCtaAdapter({
        fields: cmsComponentData?.fields,
        template: 'landingPage',
      });
    }

    default: {
      return staticData;
    }
  }
};

/**
 * getStoriesDataPropFromData - Merge CMS Customer Story entries with static Customer Story entries
 * @param {Object[]} cmsStoryEntries
 * @param {Object[]} staticStoryData
 * @returns {Object[]}
 */
export const getStoriesDataPropFromData = (
  cmsStoryEntries = [],
  staticStoryData = [],
) => {
  const toValidEntriesOnly = (validEntries, currentEntry) => {
    const allRequiredFieldsPresent =
      currentEntry.fields.pageSlug &&
      currentEntry.fields.metaTitle &&
      currentEntry.fields.metaDescription &&
      currentEntry.fields.goals &&
      currentEntry.fields.cardImage &&
      currentEntry.fields.components &&
      currentEntry.fields.excerpt &&
      currentEntry.fields.customFooterCta;

    return allRequiredFieldsPresent
      ? [...validEntries, customerStoryTileAdapter(currentEntry)]
      : validEntries;
  };

  const cmsStoriesData = cmsStoryEntries.reduce(toValidEntriesOnly, []);
  return [...cmsStoriesData, ...staticStoryData];
};

/**
 * @param {Object} cmsData
 * @param {Object} cmsData.fields - CMS entry's fields, including Featured Story related fields if provided
 * @param {Object} staticData - data/props from static source
 * @returns {Object} expected shape of props for view, using CMS data if provided otherwise using staticData as fallback
 */
export const getFeaturedStoryPropsFromData = (cmsData, staticData = {}) => {
  // All Featured Story fields from CMS are required if using template
  // If any one is undefined, we should default to the static data
  const allFieldsPresent =
    cmsData?.fields?.hasOwnProperty('featuredHeading') &&
    cmsData?.fields?.hasOwnProperty('featuredProductImage') &&
    cmsData?.fields?.hasOwnProperty('featuredStoryReference') &&
    cmsData?.fields?.featuredStoryReference?.fields &&
    cmsData?.fields?.hasOwnProperty('featuredSubhead');
  if (allFieldsPresent) {
    return featuredStoryAdapter(cmsData.fields);
  } else {
    return staticData;
  }
};
