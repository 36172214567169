import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Element } from 'react-scroll';

// constants
import { ContentfulTemplates } from 'src/lib/constants';
import { CustomerStoriesOverviewPropTypes } from 'src/lib/customer-story-utils';

// hooks
import { useCustomerStoryFilters } from 'src/hooks';

// components
import { TemplateWrapper } from 'src/templates';
import { CustomerStoriesPageHeader } from 'src/components/headers';
import {
  CustomerStoryTile,
  FeaturedStory,
} from 'src/components/CustomerStories';
import {
  CredibilityQuote,
  CredibilityCtaLogo,
  QuoteWithImage,
} from 'src/components/Credibility';
import { SocialProofCms } from 'src/components/Layout';
import Cta from 'src/components/cta';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';
const Filters = dynamic(
  () => import('src/components/CustomerStories/Filters/Filters'),
  {
    ssr: false,
  },
);
const CustomerStoriesScripts = dynamic(
  () =>
    import(
      'src/templates/CustomerStoriesTemplate/CustomerStoriesTemplateScripts'
    ),
  {
    ssr: false,
  },
);

const propTypes = CustomerStoriesOverviewPropTypes;

const CustomerStoriesTemplate = ({
  metaData,
  metaNoindex,
  locale,
  pageHeaderData,
  featuredStoryData,
  credibilityData,
  socialproofData,
  ctaData,
  storiesData,
  ...props
}) => {
  const { route } = useRouter();
  const { state, resetFilters, toggleFilter } = useCustomerStoryFilters({
    locale,
    storiesData,
  });
  return (
    <TemplateWrapper
      meta-title={metaData?.metaTitle || metaData?.['meta-title']}
      meta-description={
        metaData?.metaDescription || metaData?.['meta-description']
      }
      hasFooterCta={false}
      noIndex={metaNoindex}
      locale={locale}
    >
      <div
        className='customer-stories'
        data-testid={ContentfulTemplates.CUSTOMER_STORIES}
      >
        <CustomerStoriesPageHeader {...pageHeaderData} />
        <div className='customer-stories__scroll-boundary'>
          <Filters
            handleClick={toggleFilter}
            handleResetFilter={resetFilters}
            filters={state.filters}
          />
          <div className='customer-stories__content'>
            <Element name='stories'>
              <div className='grid-container'>
                {state.showFeaturedCard && (
                  <FeaturedStory {...featuredStoryData} />
                )}
                <div
                  className='customer-stories__collection'
                  data-id='filterable-container'
                >
                  {state.stories.map((item) => {
                    if (item.visible) {
                      return (
                        <article
                          className='customer-stories__item'
                          data-id={item.id}
                          data-tags={JSON.stringify(item.goals)}
                          key={item.id}
                        >
                          <CustomerStoryTile {...item} />
                        </article>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </Element>
          </div>
        </div>
        <div
          className='customer-stories__credibility center-content center-content--mobile'
          id='sticky-filters-bottom-anchor'
        >
          <QuoteWithImage image={credibilityData.image}>
            <CredibilityQuote {...credibilityData} />
            <CredibilityCtaLogo {...credibilityData} />
          </QuoteWithImage>
        </div>
        <div className='customer-stories__social-proof'>
          <SocialProofCms {...socialproofData} />
        </div>

        <Cta {...ctaData} />
      </div>
      <ContentfulToolbar />
      <CustomerStoriesScripts route={route} {...props} />
    </TemplateWrapper>
  );
};
CustomerStoriesTemplate.propTypes = propTypes;

export default CustomerStoriesTemplate;
