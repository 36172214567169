import React, { useEffect, useRef, FC } from 'react';

// components
import {
  JobPostWrapper,
  JobHeading,
  JobIntro,
  JobCategories,
  JobApplyButton,
  JobDescription,
  JobList,
  JobAdditional,
  FeaturedLocation,
  FeaturedPerson,
  SalaryDescription,
} from 'src/components/job';
// import { ApplyByApi } from 'src/components/Careers/ApplyByApi';
import FiveHundred from 'src/pages/500';

// helpers
import { Props } from './JobPage.types';

// data
import { locations } from 'src/store/careers.js';

// constants
import { CAREERS_PATH } from 'src/lib/constants';

/**
 * getAllLocationsData (comments by @dgowrie)
 * A temporary workaround for dealing with poorly shaped source data
 * At least for now, allows us to stay DRY with one source of data,
 * pending refactor of the data source:
 * TODO: https://jira.plaid.com/browse/SITE-1719
 * @returns {Object} locations data in shape needed by consumer component
 */
const getAllLocationsData = () => {
  // trailing slash required in staging and prod environments
  const basePath = `${CAREERS_PATH}/openings/all-departments/`;

  const locationsMappingArray = ['one', 'two', 'three', 'four', 'five', 'six'];
  return locationsMappingArray.reduce((acc, locationNumber) => {
    const accKey = locations[`office-${locationNumber}-name`];
    const imgValueSuffix = locationNumber === 'one' ? 'img-landscape' : 'img';
    const locationData = {
      name: locations[`office-${locationNumber}-name`],
      content: locations[`office-${locationNumber}-description`],
      image: locations[`office-${locationNumber}-${imgValueSuffix}`],
      linkUrl: `${basePath}${locations[`office-${locationNumber}-cta`]}`,
    };
    acc[accKey] = locationData;
    return acc;
  }, {});
};

const JobPage: FC<Props> = ({ job: post, metaData, hasError }): JSX.Element => {
  const stickyContainerRef = useRef(null);
  const applyByApiRef = useRef(null);

  useEffect(() => {
    const $stickyContainerDom = $(stickyContainerRef.current);

    // Initialize Foundation plugin after component has rendered and DOM is available
    if ($stickyContainerDom.length) {
      if ($stickyContainerDom.foundation) {
        $stickyContainerDom.foundation();
      }
    }

    // recalc ZF Sticky anchors/positioning when ZF Accordion opens/closes
    // Note: Zurb Foundation has dependency upon jQuery
    const applyByApiDom = applyByApiRef.current;
    const handleAccordionToggle = () => {
      $stickyContainerDom.foundation('_calc', true);
    };
    if (applyByApiDom) {
      applyByApiDom.addEventListener('transitionend', handleAccordionToggle);
      return function cleanup() {
        applyByApiDom.removeEventListener(
          'transitionend',
          handleAccordionToggle,
        );
      };
    }
  }, [applyByApiRef, post]);

  if (hasError) {
    return <FiveHundred />;
  }

  const { id } = post;

  return (
    <JobPostWrapper metaData={metaData}>
      <div className='grid-container'>
        <div className='grid-x'>
          <div className='cell'>
            <JobHeading {...post} />
          </div>
        </div>
        <div className='grid-x'>
          <div className='cell'>
            <JobIntro {...post} />
          </div>
        </div>
      </div>
      <div className='job-post__main'>
        <div className='grid-container'>
          <div className='grid-x grid-margin-x'>
            <div className='cell medium-4 large-3 data-sticky-container'>
              <div
                className='job-post__aside'
                data-sticky
                data-top-anchor='job-post-top-anchor'
                data-btm-anchor='job-post-btm-anchor:bottom'
                data-margin-top='2'
                ref={stickyContainerRef}
              >
                <JobApplyButton jobId={id} />
                <JobCategories {...post} />
              </div>
            </div>
            <div className='cell medium-8 large-8 large-offset-1'>
              <div className='job-post__content' id='job-post-top-anchor'>
                <JobDescription {...post} />
                <JobList {...post} />
                <SalaryDescription
                  salaryRange={post?.salaryRange}
                  salaryDescription={post?.salaryDescription}
                />
                <JobAdditional {...post} />
                <div className='job-post__footer' id='job-post-btm-anchor'>
                  {/* <div className='job-post__apply-by-api'>
                    <ApplyByApi
                      isConstrained
                      forwardedRef={applyByApiRef}
                      jobId={id}
                    />
                  </div> */}
                </div>
              </div>
              {/* /.job-post__content */}
            </div>
          </div>
          <div className='grid-x'>
            <div className='cell show-for-small-only'>
              <div className='job-post__apply-mobile'>
                <JobApplyButton jobId={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.job-post__main */}
      <div className='job-post__related-features'>
        <div className='grid-container'>
          <div className='grid-x'>
            <div className='cell large-6'>
              <FeaturedLocation
                allLocationsData={getAllLocationsData()}
                {...post}
              />
            </div>
            <div className='cell large-6'>
              <FeaturedPerson {...post} />
            </div>
          </div>
        </div>
      </div>
    </JobPostWrapper>
  );
};

export default JobPage;
