// TODO: make this global?
const getPreloadedImages = (fields) => {
  if (fields?.backgroundImage) {
    return {
      preloadImages: [
        {
          src: fields?.backgroundImage?.fields?.file?.url || '',
        },
      ],
    };
  }
  return {};
};

type Image = {
  src: string;
};

interface IMetadataAdapter {
  'meta-title': string;
  'meta-description': string;
  noIndex: boolean;
  preloadImages?: Array<Image>;
  hasFooter: boolean;
  hasFooterCta: boolean;
  openGraphImage?: string;
}

// TODO: make this global?
export const mainTemplateMetaTagAdapter = ({ fields }): IMetadataAdapter => {
  return {
    'meta-description': fields?.metaDescription || '',
    'meta-title': fields?.metaTitle || '',
    hasFooter: true,
    hasFooterCta: fields?.footerCta || false,
    noIndex: fields?.blockSearchIndexing || false,
    openGraphImage: fields?.openGraphImage?.fields?.file?.url || '',
    ...getPreloadedImages(fields),
    // TODO: locale
  };
};

const getBackgroundImage = (fields) => {
  if (fields?.backgroundImage?.fields?.file?.url) {
    return {
      backgroundImage: `url('${fields?.backgroundImage?.fields?.file?.url.replace(
        /\?(.+)/,
        '?fm=webp&q=1', // we don't set a width since we do not know the size of the uploaded image
      )}')`,
    };
  }
  return {};
};

// TODO: type this
export const mainTemplateStylesAdapter = ({ fields }) => {
  return {
    sx: {
      ...getBackgroundImage(fields),
      backgroundRepeat: 'no-repeat',
      bgcolor: fields?.backgroundColor?.fields.color || 'white',
      ...(fields?.styles || {}),
    },
    navbarStyles: {
      sx: { bgcolor: fields?.backgroundColor?.fields.color || 'white' },
    },
  };
};
